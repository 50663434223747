import {RichTextContent} from "contentful";


export interface Content {
    content: any;
    data: any;
    nodeType: string;
}
export interface AppLandingPageLink extends  BaseContent {
    linkImage?: ImageObject;
    linkUrl?: string;
}
export interface AppLandingPagePayload {
    nameId?: string;
    topDynamicContent?: BaseContent[];
    links? : AppLandingPageLink[]
}
export interface LandingPagePayload {
    title: string;
    subtitle: string;
    buttonTitle: string,
    content1: string;
    content2: string;
    backgroundImage: ImageObject;
    innerImage: ImageObject;
    promotionAreaTitle?: string;
    promotionArea?: ImageObject[];
    bottomPromotion?: string;
    dynamicContent?: BaseContent[];
    bannerContentPosition?: string[] | undefined;
    bannerSignupForm?: boolean | undefined;
    sliderPromotionArea?: boolean | undefined;
    sliderPromotionAreaTiming?: string[] | undefined;
    seo?:[SEO];
    appmode?: string;
    siteNameId?: string;
    glifeGameCategories?: Array<{
        title: string;
        link: string;
    }>;
}
export interface CasinoPagePayload  extends Content {
    name?: string;
    casinoPageId?:number;
    banners? : CasinoBannerPayload[];
    dynamicContent?: BaseContent[];
    seo? : SEO;
    topDynamicContent?: BaseContent[];
}
export interface Banner extends BaseContent {
    name? : string;
    image?: ImageObject;
    imageUrl?: string;
    mobileImage?: ImageObject;
    mobileImageUrl?: string;
    gameId? : number;
    link?: string;
    loggedInLink?: string;
    linkType? : "BUTTON" | "IMAGE" | "BUTTON_AND_IMAGE";
    linkText? : string;
    loggedInLinkText? : string;
    excludedCountries? : string[] | string;
    includedCountries?: string[] | string;
    jackpotId? : string;
    jackpotCurrency? : string;
    containerCssClass? : string;
}
export interface BannerCarousel extends BaseContent {
    name? : string;
    gridSize?: number;
    banners : Banner[];
    containerCssClass? : string;
}
export interface CasinoBannerPayload  {
    title?: string;
    backgroundImage: ImageObject;
    smallImage? : ImageObject;
    mobileImage? : ImageObject;
    content? : string;
    gameId? : number;
    excludedCountries?:string[]|string;
    includedCountries?:string[]|string;
    jackpotId?: string;
    jackpotCurrency? : string;
}

// add types here that belongs to dynamicContent example PageSection | Leaderboard
export type DynamicContentType = PageSection | SectionBoxes | GameListSection | GameSpotlight;

export interface DynamicContentPayload {
    fields: DynamicContentType;
    sys: {contentType: {sys: {id: string}}, id: {id: string}}
}
export interface BaseContent {
    _contentType? : string;
    _id? : string;
    __component?: string; //strapi content
}

export interface StyledText extends BaseContent{
    text?: string;
    richText? : RichTextContent;
    html?: string;
    cssClassName? : string;
    textLink? : string;
    hideLoggedIn?: boolean;
    hideLoggedOut?: boolean;
    showSideMenu?: boolean;
    horizontalScrollingText?: boolean;
}

export interface StyledTextVideo extends BaseContent{
    title?: string;
    poster?: ImageObject;
    video?: ImageObject;
    cssClass?: string;

}
export interface HeroBannerTwoColumns extends BaseContent {
    image?: ImageObject;
    styledTexts? : StyledText[];
    backgroundImage? : ImageObject;
    topOfPage? : boolean;
    cssClassName? : string;
    styledTextList? : StyledTextList[];
}

export interface StyledTextList extends BaseContent {
    styledTexts? : StyledText[];
    title?: string;
    cssClassName? : string;
}



export interface PageSection extends BaseContent{
    image?: ImageObject;
    text: any;
    textColor: string;
    imageLeftSide: boolean;
    backgroundColor: string;
    borderColor: string;
    name?: string;
    cssClassName? : string;
    type?: string;
}


export interface ImageObject {
    fields: {file: {url: string, details: any, fileName: string, contentType: string}, title: string, description: string};
    file? : any;
    title? : string;
    description? : string;
}


export interface menuContent {
    title?: string;
    tagline?: string;
    richText?: any
    textLink?: string;
    cssClassName?: string;
    image?: ImageObject;
    html?: string;
    loggedIn?: boolean;
    collapsedMode?: boolean;
}

export interface SideMenuContentPayload {
    title: string;
    tagline : string;
    image : ImageObject;
    html?: string;
    styledTextList?: StyledTextList[];
    menuBottomContent?: menuContent;
    menuTopContent: menuContent[]
}

export interface DynamicModalContent {
    modalType: string;
    active: boolean;
    title: string;
    text: string;
    image: ImageObject;
    backgroundImage: ImageObject;
  }

export interface OnboardingPagePayload {
    registerSuccessLogo: ImageObject;
    loginModalImage: ImageObject;
    loginModalTitle: string;
    loginModalText: string
    registrationModalImage: ImageObject;
    registrationModalTitle: string;
    registrationModalText: string;
    dynamicContent: DynamicModalContent[];
}

export enum ContentModelName {
    LANDINGPAGE = "landingpage",
    ONBOARDINGPAGE = "onboardingpage",
    GLOBAL = "global",
    POKERPAGE = "pokerPage",
    PROFILEPAGE = "profilePage",
    INFOPAGE = "infoPage",
    CASINO_BANNER ="casinoBanner",
    CASINO_PAGE ="casinoPage",
    FOOTER = "footer",
    PAGESECTION = "pageSection",
    SECTIONBOXES = "sectionBoxes",
    POPUPAPPINSTALL = "popupAppInstall",
    POPUPNOTIFICATION = "popupNotification",
    POPUPMANAGER = "popupManager",
    SPORTSPAGE = "sportsPage",
    INFOPAGEACCORDION = "infoPageAccordion",
    GAMEPROMO = "gamePromo",
    GAMELISTSECTION = "gameListSection",
    GAMESPOTLIGHT = "gameSpotlight",
    SIDE_MENU_CONTENT = "sideMenuContent",
    PROMOPAGE = "promoPage",
    USERLEVELS = "userLevels",
    BADGELIST = "badgeList",
    NEWSARTICLE = "newsArticle",
    NEWS_PAGE_BANNER = "newsPageBanner",
    PANEL_SECTION = "panelSection",
    APP_LANDING_PAGE = "appLandingPage"
}
export const STRAPI_COLLECTIONS= [
    {
        model : ContentModelName.CASINO_PAGE, path : "casino-page"
    },
    {
        model : ContentModelName.LANDINGPAGE, path : "landing-page"
    },
    {
        model : ContentModelName.POKERPAGE, path : "poker-page"
    },
    {
        model : ContentModelName.INFOPAGE, path : "info-page"
    },
    {
        model : ContentModelName.FOOTER, path : "footer"
    },
    {
        model : ContentModelName.GLOBAL, path : "global", single : true
    },
    {
        model : ContentModelName.POKERPAGE, path : "poker-page"
    },
    {
        model : ContentModelName.PROFILEPAGE, path : "profile-page"
    },
    {
        model : ContentModelName.POPUPAPPINSTALL, path : "popup-app-install", single: true
    },
    {
        model : ContentModelName.POPUPNOTIFICATION, path : "popup-notification", single: true
    },
    {
        model : ContentModelName.POPUPMANAGER, path : "popup-manager", single : true
    },
    {
        model : ContentModelName.SPORTSPAGE, path : "sports-page"
    },
    {
        model : ContentModelName.SIDE_MENU_CONTENT, path : "side-menu-content"
    },
    {
        model : ContentModelName.PROMOPAGE, path : "promo-page"
    },
    {
        model : ContentModelName.USERLEVELS, path : "user-level"
    },
    {
        model : ContentModelName.NEWSARTICLE, path : "news-article"
    },
    {
        model : ContentModelName.NEWS_PAGE_BANNER, path : "news-page-banner"
    },
    {
        model:  ContentModelName.ONBOARDINGPAGE,  path : "onboarding-page"
    },
    {
        model: ContentModelName.APP_LANDING_PAGE, path: "app-landing-page"
    }
];
export interface GlobalPayload {
    brandName:string;
    logo: ImageObject;
    logoDark: ImageObject;
    favicon: ImageObject;
    blockedCountryText: any;
}

export interface PokerPagePayload {
    mainTitle: string;
    subtitle: string;
    banner: ImageObject;
    playButtonText: string;
    promotionType: string;
    promotion: string;
    promotionTime: string;
    dynamicContent?: BaseContent[];
    seo?:SEO;
}

export interface FooterPayload {
    infoPages: Array<InfoPagePayload>;
    infoPagesHighlighted: Array<InfoPagePayload>;
    images: ImageObject[];
    text?: string;
    dynamicContent?: BaseContent[];
}

export interface InfoPage extends BaseContent{
    body: any;
    nameId?: string;
    highlight: boolean;
    order: number;
    id: string;
    dynamicContent?: BaseContent[];
    seo?:SEO;
    containerCss?: string;
}

export interface InfoPagePayload {
    fields: InfoPage;
    id: string;
    _id?: number;
    nameId?: string;
}

export interface Locale {
    code: string;
    name: string;
    defaultLocale?: boolean;
    fallback: string | null;
}

export interface SectionBoxes {
    name: string;
    title1?: string;
    title2?: string;
    title3?: string;
    title4?: string;
    text1?: any;
    text2?: any;
    text3?: any;
    text4?: any;
    icon1?: ImageObject;
    icon2?: ImageObject;
    icon3?: ImageObject;
    icon4?: ImageObject;
    textColor?: string;
    backgroundColorBox1?: string;
    backgroundColorBox2?: string;
    backgroundColorBox3?: string;
    backgroundColorBox4?: string;
    backgroundColorSection?: string;
    backgroundGradientSection?: string;
    backgroundImage?: ImageObject;
    borderColor?: string;
    iconLeft?: boolean;
    circleBox?: boolean;
    borderColorBox?: string;
    cssClassName?: string;
    link1: string;
    link2: string;
    link3: string;
    link4: string;
}
export type DeviceOS = "Windows" | "iOS" | "Android" | "Mac OS" | "Linux" | "unknown";

export interface PopupAppInstall {
    image?: ImageObject;
    title?: string;
    description?: string;
    buttonText?: string;
}

export type PopupType = Popup;

export interface PopupPayload {
    id: string;
    header?: string;
    text?: any;
    image?: ImageObject;
    showOnlyOnce?: boolean;
    disableOutsideClick?: boolean;
    imageRedirect?:string;
    fields: PopupType;
    sys: {contentType: {sys: {id: string}}};
}
export interface Popup {
    id: string;
    header?: string;
    text?: any;
    image?: ImageObject;
    showOnlyOnce?: boolean;
    imageRedirect?:string;
}

export interface PopupManager {
    id: string;
    login?: PopupPayload;
    visit?: PopupPayload;
}

export interface PopupNotification {
    title?:string;
    message?:string;
    approveButtonText?:string;
    declineButtonText?:string;
}
export interface SportsPagePayload {
    dynamicContent?: BaseContent[];
    providers: Providers;
    seo?:SEO;
}
export interface Providers{
    id?: number;
    name?: string;
    provider?: Provider[];
    _id?: string;
}
export interface Provider{
    name: string;
    title?: string;
    description?: string;
    image?: ImageObject
    imageUrl?: string;
    mobileImage?: ImageObject
    mobileImageUrl?: string;
    linkType? : "BUTTON" | "IMAGE" | "BUTTON_AND_IMAGE";
    link?: string;
    linkText?: string;
    containerCssClass?: string;
}



export interface InfoPageAccordion {
    title?: string;
    text?: string;
}

export interface GamePromo extends BaseContent {
    name?: string;
    gameProvider?: string;
    backgroundImage?: ImageObject;
    logoImage?: ImageObject;
    gameId?: string;
    gameProviderId?: string;
    new?: boolean;
    maxNrOfCategoryGames?: number;
}

export interface GameSliderItem extends BaseContent {
    name: string;
    gameId: string;
    backgroundImage: ImageObject;
    logoImage: ImageObject;
}

export interface GameListSection extends BaseContent{
    id?: string;
    title?: string;
    titleIcon?: ImageObject;
    listOfGamePromo?: GamePromo[];
    backgroundColor?: string;
    categoryLink?: string;
    categoryName?: string;
    categoryId?: number;
    description?: string;
    cssClassName?:  string;
    maxNrOfGames? : number;
}

export interface GameSpotlight extends BaseContent{
    title?: string;
    gameId?: string;
    gameType?: string;
    provider?: string;
    description?: any;
    backgroundImage?: ImageObject;
    logoImage?: ImageObject;
    backgroundAnimationType?: string[];
    logoAnimationType?: string[];
    backgroundColor?: string;
    gameName?: string;
}

export interface Badges extends BaseContent {
    name? : string;
    icon?: ImageObject;
    text?: any;
    badgeImage?: ImageObject;
    cssClassName? : string;
}

export interface Badges extends BaseContent {
    name? : string;
    icon?: ImageObject;
    text?: any;
    badgeImage?: ImageObject;
    cssClassName? : string;
}

export interface BadgeList extends BaseContent {
    name?: string;
    badges? : Badges[];
    cssClassName? : string;
}

export interface BetbyBanner extends BaseContent {
    place_holder: string;
}

export interface GamesSliderProps extends BaseContent {
    title: string;
    titleIcon?: ImageObject;
    cssClassName?: string;
    gameSliderItems: GameSliderItem[];
    showAllLink?: string;
    slidesPerViewDesktop: number;
    slidesPerViewMobile: number;
    swipeStepSizePerClick: number;
}

export interface PromoPage {
    name?: string;
    heroContent1?: string;
    heroContent2?: string;
    buttonTitle?: string;
    heroBackground?: ImageObject;
    heroImage1?: ImageObject;
    heroImage1Animate?: boolean;
    heroImage2?: ImageObject;
    heroImage2Animate?: boolean;
    dynamicContent?: BaseContent[];
    cssClassName?: string;
    id?: string;
    nameId?: string;
    seo?: SEO;
}

export interface UserLevels extends BaseContent {
    information?: any;
    icons?: ImageObject[];
}

export interface NewsArticle {
    slug?: string;
    header?: string;
    articleContent?: any;
    image?: ImageObject;
    date?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    metaOgTitle?: string;
    metaOgDescription?: string;
    cssClassName?: string;
}

export interface NewsPageBanner {
    name?: string;
    background?: ImageObject;
    title?: string;
    cssClassName?: string;
    seo?:SEO;
}

export interface SEO {
    title?: string;
    description?: string;
    image?: ImageObject;
}

export interface Panel extends BaseContent{
    name?: string;
    styledTexts? : StyledText[];
    icon? : ImageObject;
    backgroundColor? : string;
    backgroundGradient?: string;
    linkTo?: string;
    cssClassName?: string;
    overview? : StyledTextList;
    backgroundImage? : ImageObject;
    gameId? : number;
    gameName?: string;
    jackpotId?: string;
    jackpotCurrency? : string;
}

export interface PanelSection extends BaseContent {
    name?: string;
    panels? : Panel[];
    fullWidth? : boolean;
    cssClassName? : string;
    type?: string;
}